<template>

	<div>

<!--
	title		"Building a 5 Stepper Driver Board w/WiFi & BLE (Part 4)"
choosed		0
	lists		[]
	ident		"TDFXIImxDRk"
	url			"http://..."
-->

		<v-dialog
			fullscreen
			v-model="show"
			hide-overlay
			transition="dialog-bottom-transition"
			id="playerDialogHelper"
		>

			<v-card
				height="100"
			>

				<v-toolbar>

					<template v-if="!video.external">

						<v-tooltip bottom>
							
							<template v-slot:activator="{ on, attrs }">

								<v-btn
									icon
									@click="toggleArchive()"
									:style="{ color: video.viewed ? 'orange' : '' }"
								>

									<v-icon
										v-on="on"
										v-bind="attrs"
									>{{ video.viewed ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
								
								</v-btn>
								
							</template>
							
							<span>{{ video.viewed ? 'из архива' : 'в архив' }}</span>

						</v-tooltip>

					</template>

					<v-toolbar-title
						@dblclick="translateText(video.title)"
					>{{ video.title }}</v-toolbar-title>

					<v-progress-linear
						:active="loading"
						absolute
						bottom
						indeterminate
					/>

					<v-spacer/>

					<!-- -->
<!--
					<v-tooltip
						v-if="video.speed !== 1"
						bottom
					>
						
						<template v-slot:activator="{ on, attrs }">
						
							<v-btn
								icon
								plain
								v-on="on"
								v-bind="attrs"
							>
								<v-icon v-if="video.speed < 1.5">mdi-speedometer-slow</v-icon>
								<v-icon v-else-if="video.speed == 1.5">mdi-speedometer-medium</v-icon>
								<v-icon v-else>mdi-speedometer</v-icon>
							</v-btn>
						
						</template>
						
						<span>Скорость {{ video.speed }}</span>

					</v-tooltip>
-->

					<v-tooltip
						bottom
					>
						
						<template v-slot:activator="{ on, attrs }">

							<v-chip
								label
								v-on="on"
								v-bind="attrs"
								class="secondary"
							>
								{{ video.speed }}
							</v-chip>

						</template>
						
						<span>Скорость</span>

					</v-tooltip>







<!--
<v-chip
label
class="ma-2"
>
<v-avatar
left
class="secondary"
>
{{ video.speed }}
</v-avatar>
скорость
</v-chip>
-->


					<!-- -->

					<template v-if="!video.external">

						<v-btn icon @click="switchVideo('forward')">
							<v-icon>mdi-arrow-left-bold</v-icon>
						</v-btn>

						<v-btn icon @click="switchVideo('backward')">
							<v-icon>mdi-arrow-right-bold</v-icon>
						</v-btn>

					</template>

					<v-btn
						icon
						@click="toggleFavourite()"
					>

						<v-icon
							:color="video.favourite ? 'red' : ''"
						>mdi-heart</v-icon>
					
					</v-btn>
								
					<v-btn
						icon
						@click="yvtTranslate()"
					>
<!--
							:class="{ 'highlight': audio.active }"
-->
						<v-icon
							:color="audio.active ? 'red' : ''"
						>mdi-translate</v-icon>
					</v-btn>

					<!-- -->

					<template v-if="!video.external">

						<v-menu
							left
							bottom
						>
						
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									icon
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-format-list-checks</v-icon>
								</v-btn>
							</template>

							<v-list>

								<v-list-item
									link
									dense
									v-for="list in video.lists.available"
									:key="list.value"
									@click="toggleList(list.value)"
								>

									<v-list-item-icon
										v-if="video.lists.selected && video.lists.selected.includes(list.value)"
									>
										<v-icon>mdi-checkbox-marked</v-icon>
									</v-list-item-icon>

									<v-list-item-icon
										v-else
									>
										<v-icon>mdi-checkbox-blank-outline</v-icon>
									</v-list-item-icon>

									<!-- -->

									<v-list-item-title>
										{{ list.title }}
									</v-list-item-title>

								</v-list-item>

							</v-list>
						
						</v-menu>

						<v-btn
							icon
							@click.stop="emitPutOpenInfo"
						>
							<v-icon>mdi-information</v-icon>
						</v-btn>

					</template>

					<v-btn icon @click="show = false">
						<v-icon>mdi-close-thick</v-icon>
					</v-btn>

				</v-toolbar>

				<div id="YTPlayerHolder"></div>

			</v-card>

		</v-dialog>

		<!-- -->
		<g-notify
			:message="notify.message"
			:timeout="notify.timeout"
			:type="notify.type"
			v-model="notify.show"
		/>

	</div>

</template>

<script>

// https://github.com/ilyhalight/voice-over-translation/blob/master/vot.user.js
	
import Debug			from '@/mixins/Debug'
import NetworkExchange	from '@/mixins/networkExchange'
import LocalStorage		from '@/mixins/localStorage'

export default {
	
	name: 'VideoPlayer',

	//
	
	props: {

		ident: {
			type: String,
			required: true
		},
		
		value: {
			type: Boolean,
			required: false,
		},

	},
	
	//

	mixins: [
		Debug,
		NetworkExchange,
		LocalStorage,
	],

	//
	
	data: () => ({

		notify: {
			show:		false,
			type:		'notice',
			message:	'',
			timeout:	2500,
		},
		
		//
		
		loading: false,
		
		//
		
		video: {
			url:		'',
			ident:		'',
			title:		'',
			speed:		1,
			viewed:		false,
			lists:	{
				selected:	[],
				available:	[],
			},
		},
		
		showVideoInfo: false,
		
		//
		
		player: {

			active: false,

			obj:	null,

		},

		//
		
		audio: {
			
			active: false,
			
			obj:	null,

		},

	}),

	//
	
	watch: {

		value: function() {

			if(this.value) {

				this.loading = true

				//
				
				this.audioInit()
				
				//

				this.nwGet(
					{
						method:		'video.player',
						//
						ident:		this.ident,
						//
						random:		new Date().getTime(),
						auth:		this.lsGet('auth', null),
					},
					(data) => {

						this.video = data
						
						this.ytpCreateVideo(data.ident, data.progress)

					},
					(state) => {
						this.loading = state
					},
				)
				
			}
			
			else {

				this.ytpDestroyVideo(this.ident)

				this.video = {
					url:		'',
					ident:		'',
					title:		'Идет загрузка данных...',
					lists:		[],
				}
				
			}

		}

	},
	
	//
	
	computed: {

		show: {
		
			get() {
				return this.value
			},
			
			set(value) {
				this.$emit('input', value)
			}
		
		},

	},

	//
	
	methods: {

		switchVideo(direction) {

			let duration = this.lsGet('minduration', 1)

			this.nwGet(
				{
					method:		'video.controls.switch',
					//
					ident:		this.video.ident,
					direction:	direction,
					duration:	duration,
					//
					random:		new Date().getTime(),
					auth:		this.lsGet('auth', null),
				},
				(data) => {

					this.ytpDestroyVideo(this.video.ident)

					this.video = data
					
					this.ytpCreateVideo(data.ident, data.progress)
					
					this.emitPutRefreshIdent(data.ident)

				},
				(state) => {
					this.loading = state
				},
			)

		},

		//

		toggleList(list) {

			this.loading = true

			this.nwPut(
				{
					method:		'video.controls.list',
					//
					list:		list,
					video:		this.ident,
					//
					auth:		this.lsGet('auth', null),
				},
				(data) => {

					this.video.lists.selected = data

				},
				(state) => {
					this.loading = state
				},
			)

		},

		//
		
		toggleFavourite() {

			this.nwPut(
				{
					method:		'video.controls.favourite',
					//
					video:		this.ident,
					//
					auth:		this.lsGet('auth', null),
				},
				(data) => {

					this.video.favourite = !this.video.favourite

				},
				(state) => {
					this.loading = state
				},
			)
			
		},

		//
		
		toggleArchive() {

			this.loading = true

			this.nwPut(
				{
					method:		'video.controls.viewed',
					//
					video:		this.ident,
					//
					auth:		this.lsGet('auth', null),
				},
				(data) => {

					this.video.viewed = !this.video.viewed

				},
				(state) => {
					this.loading = state
				},
			)

		},

		//
		
		translateText(text) {

			this.loading = true
			
			//

			this.nwPut(
				{
					method:		'video.translate.text',
					query:		text,
					//
					auth:		this.lsGet('auth', null),
				},
				(data) => {

alert(data)

				},
				(state) => {
					this.loading = state
				},
			)

		},

		//

		saveProgress(ident) {

			if(!this.video.external) {

				this.loading = true

				this.nwPut(
					{
						method:		'video.controls.progress',
						//
						video:		ident,
						progress:	Math.round(this.ytpGetPosition()),
						//
						auth:		this.lsGet('auth', null),
					},
					(data) => {},
					(state) => {
						this.loading = state
					},
				)
			
			}

		},

		// -------  ----------------------------------------
		//	EMITS	( https://ru.vuejs.org/v2/api/#vm-emit )
		// -------  ----------------------------------------

		emitPutOpenInfo() {
			this.$emit('open-info', {
				translate: {
					url:	this.audio.obj.src,
					name:	this.video.ident +'.mp3',
				},
			})
		},
		
		emitPutRefreshIdent(ident) {
			this.$emit('refresh-ident', ident)
		},
		
		// ----------------------------
		//	YANDEX TRANSLATE FUNCTIONS
		// ----------------------------
		
		yvtUUID() {
			return ([1e7] + 1e3 + 4e3 + 8e3 + 1e11)
				.replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4)
				.toString(16))
		},

		// https://protobuf-decoder.netlify.app/
		// ( https://github.com/ilyhalight/voice-over-translation/releases/tag/1.5.1.4 )
		
		// дефолтные значения не передаются, они введены при обновлении ф-ции
		// участки, что закомменчены - хер знает как пользовать))) тоже взято из исходников с гита
		yvtEncode(url, duration = 343, requestLang = 'auto', responseLang = 'ru') {

			let protobuf = window.protobuf

			// from src\yandexProtobuf.js
			let protoRequest = new protobuf.Type('VideoTranslationRequest')
				.add(new protobuf.Field('url',				3,	'string'))
				.add(new protobuf.Field('deviceId',			4,	'string'))	// used in mobile version
				.add(new protobuf.Field('firstRequest', 	5,	'bool'))	// true for the first request, false for subsequent ones
				.add(new protobuf.Field('duration',			6,	'double'))
				.add(new protobuf.Field('unknown2',			7,	'int32'))	// 1 1
				.add(new protobuf.Field('language',			8,	'string'))	// source language code
				.add(new protobuf.Field('unknown3',			9,	'int32'))	// 0 - without translationHelp | 1 - with translationHelp (??? But it works without it)
				.add(new protobuf.Field('unknown4',			10,	'int32'))	// 0 0
/*
				.add(
					new protobuf.Field(
						'translationHelp',
						11,
						'VideoTranslationHelpObject',
						'repeated',
					),
				)															// array for translation assistance ([0] -> {2: link to video, 1: 'video_file_url'}, [1] -> {2: link to subtitles, 1: 'subtitles_file_url'})
*/
				.add(new protobuf.Field('responseLanguage',	14,	'string'))
				.add(new protobuf.Field('unknown5',			15,	'int32'))	// 0
				.add(new protobuf.Field('unknown6',			16,	'int32'))	// 1
				.add(new protobuf.Field('unknown7',			17,	'int32'))	// 0

			return protoRequest.encode({
//				deviceId:	this.yvtUUID(),
				url:				url,
				firstRequest:		true,
				duration:			duration,
				unknown2:			1,
				language:			requestLang,
				unknown3:			0,
				unknown4:			0,
//				translationHelp,
				translationHelp:	null,
				responseLanguage:	responseLang,
				unknown5:			0,
				unknown6:			1,
				unknown7:			0,
			})
			.finish()

		},

		yvtDecode(response) {

			let protobuf = window.protobuf

			let protoResponse = new protobuf.Type('VideoTranslationResponse')
				.add(new protobuf.Field('url',				1,	'string'))
				.add(new protobuf.Field('duration',			2,	'double'))
				.add(new protobuf.Field('status',			4,	'int32'))
				.add(new protobuf.Field('remainingTime',	5,	'int32'))	// secs before translation (used as interval before next request in yaBrowser)
				.add(new protobuf.Field('unknown0',			6,	'int32'))	// unknown 0 (1st request) -> 10 (2nd, 3th and etc requests)
				.add(new protobuf.Field('unknown1',			7,	'string'))
				.add(new protobuf.Field('language',			8,	'string'))	// detected language (if the wrong one is set)
				.add(new protobuf.Field('message',			9,	'string'))

			return protoResponse.decode(new Uint8Array(response))

		},
		
		//
		
		yvtTranslate() {

			//
			//	TURN ON
			//
			if(this.audio.active == false) {
				
				this.loading = true
				
				//
				
				var body = this.yvtEncode('https://youtu.be/'+ this.video.ident)

				//

				this.nwPut(
					{
						method:		'video.translate.audio',
						request:	window.btoa(body.join(':')),
						//
						auth:		this.lsGet('auth', null),
					},
					(data) => {

						let binary = window.atob(data)

						let json = JSON.parse(binary)

						let decoded = this.yvtDecode(json)

						//

						switch(decoded.status) {

							case 0:

								this.notify.type = 'error'

								this.notify.message = decoded.message

								this.notify.show = true

							break

							//
							
							case 1:

								this._debug('Translated audio: ', decoded.url)
								
								//

								this.notify.type = 'notice'

								this.notify.message = 'Перевод готов'

								this.notify.show = true

								//

								this.audioCreate(decoded.url)

								this.audioSetSpeed(this.ytpGetSpeed())

								// == 1 -> playing
								if(this.ytpGetState() == 1) {
									this.audioPlay()
									this.audioSeek(this.ytpGetPosition())
								}

							break

							//
							
							case 2:

								this.notify.type = 'warning'

								this.notify.message = 'Перевод в обработке'

								this.notify.show = true

							break

						}

					},
					(state) => {
						this.loading = state
					},
				)

			}
			
			//
			//	TURN OFF
			//
			else {
				this.audioDestroy()
			}

		},		

		// -------------------------
		//	YouTube Player Routines
		// -------------------------

		// https://developers.google.com/youtube/iframe_api_reference?hl=ru

		ytpCreateVideo(ident, progress) {

			if(window.g_youTubeApiIsReady) {
				
				this.player.active = true

				// 64px - height of toolbar (+8 - experemental =) )
				this.player.obj = new window.YT.Player('YTPlayerHolder', {
					width:		window.innerWidth,
					height:		window.innerHeight - 64 - 8,
					videoId:	ident,
					// https://developers.google.com/youtube/player_parameters.html?playerVersion=HTML5&hl=ru
					playerVars:	{
						'cc_load_policy':		0,
						'iv_load_policy':		3,
						'start':				progress,
					},
					events:		{
						'onReady':				this.ytpEventOnReady,
						'onPlaybackRateChange': this.ytpEventOnPlaybackRateChange,
						'onStateChange':		this.ytpEventOnStateChange,
					}
				})

			}
			
			else {

				this.notify.type = 'error'

				this.notify.message = 'Ошибка создания плеера'

				this.notify.show = true

			}

		},

		// Удаление проигрывателя
		ytpDestroyVideo(ident) {
			if(this.player.active) {

				this.player.active = true
				
				this.player.obj.destroy()

				this.audioDestroy()
				
				this.saveProgress(ident)
				
			}
		},
		
		//

// !!!
		// Получение текущей скорости воспроизведения видео
		ytpGetSpeed() {

			if(this.player.active) {
				return this.player.obj.getPlaybackRate()
			}

			return 0

		},

// !!!
		// Получение списка поддерживаемых схоростей воспроизведения видео
		ytpGetSpeeds() {

			if(this.player.active) {
				return this.player.obj.getAvailablePlaybackRates()
			}

			return []

		},

// !!!
		// Получение громкости видео
		ytpGetVolume() {

			if(this.player.active) {
//???			this.player.obj.getVolume()
			}

			return 0

		},

		// Получение текущей позиции воспроизведения (сек)
		ytpGetPosition() {

			if(this.player.active) {
				return this.player.obj.getCurrentTime()
			}

			return 0

		},

		// Получение статуса воспроизведения
		ytpGetState() {

			/*
				-1 – воспроизведение видео не началось
				0 – воспроизведение видео завершено
				1 – воспроизведение
				2 – пауза
				3 – буферизация
				5 – видео находится в очереди
			*/

			if(this.player.active) {
				return this.player.obj.getPlayerState()
			}

			return -1

		},

		//

		// Установка скорости воспроизведения видео
		ytpSetSpeed(speed) {
			if(this.player.active) {

				this.player.obj.setPlaybackRate(speed)

			}
		},
// !!!
		// Установка громкости видео
		ytpSetVolume(level) {
			if(this.player.active) {

				this.player.obj.setVolume(level)

			}
		},

		//

		// Событие готовности плеера к воспроизведению
		ytpEventOnReady(event) {
this._debug('ytpEventOnReady')
			this.ytpSetSpeed(this.video.speed)
		},

		// Событие изменения скорости видео
		ytpEventOnPlaybackRateChange(event) {
this._debug('ytpEventOnPlaybackRateChange')
			let speed = event.target.getPlaybackRate()

			this.audioSetSpeed(speed)

			//

			/*
				Т.к. при инициализации вызывается установка скорости через ytpSetSpeed(), то срабатывает текущий эвент ytpEventOnPlaybackRateChange() и вызывает метод сохранения скорости в БД, что делать нужно ТОЛЬКО при ручной становке скорости
				
				В общем, скорость пишется в БД только в том случае, если она отличается от уже хранящейся в БД (это как раз блокирует выше описанную броблему)
			*/
			if(speed !== this.video.speed) {
				
				if(!this.video.external) {
					
					this.loading = true
					
					//

					this.nwPut(
						{
							method:		'change.speed',
							//
							ident:		this.video.ident,
							speed:		speed,
							//
							auth:		this.lsGet('auth', null),
						},
						(data) => {
							this.video.speed = speed
						},
						(state) => {
							this.loading = state
						},
					)
					
				}
				
			}

		},

		// События плеера
		ytpEventOnStateChange(event) {
this._debug('ytpEventOnStateChange')
			switch(event.data) {

				//	???							-1 (воспроизведение видео не начато)
				//	YT.PlayerState.CUED			5 (видео подают реплики).

				//	YT.PlayerState.ENDED		0 (воспроизведение видео завершено)
				case 0:
					
					this.audioPause()
					
					this.saveProgress(this.ident)

				break

				//	YT.PlayerState.PLAYING		1 (воспроизведение)
				case 1:

					this.audioPlay()
					
					this.audioSeek(this.ytpGetPosition())
					
					this.audioSetSpeed(this.ytpGetSpeed())

					this.saveProgress(this.ident)

				break

				//	YT.PlayerState.PAUSED		2 (пауза)
				case 2:

					this.audioPause()

					this.saveProgress(this.ident)

				break

				//	YT.PlayerState.BUFFERING	3 (буферизация)
				case 3:
					this.audioPause()
				break

			}

		},

		// ----------------
		//	Audio Routines
		// ----------------

		// https://developer.mozilla.org/en-US/docs/Web/API/HTMLAudioElement/Audio

		audioInit() {
this._debug('audioInit')
			this.audio.obj = new Audio()
		},

		audioCreate(url) {
			if(!this.audio.active) {
this._debug('audioCreate', url)
				this.audio.active = true
				//
				this.audio.obj.src = url
				this.audio.obj.volume = 1
			}
		},
		
		audioDestroy() {
			if(this.audio.active) {
this._debug('audioDestroy')
				this.audio.active = false
				//
				this.audio.obj.src = ''
				this.audio.obj.removeAttribute('src')
			}
		},
		
		audioPause() {
			if(this.audio.active) {
this._debug('audioPause')
				this.audio.obj.pause()
			}
		},
		
		audioSeek(position) {
			if(this.audio.active) {
this._debug('audioSeek')
				this.audio.obj.currentTime = position	//	video.currentTime
			}
		},
		
		audioSetSpeed(speed) {
			if(this.audio.active) {
this._debug('audioSetSpeed')
				this.audio.obj.playbackRate = speed		//	video.playbackRate
			}
		},

		audioPlay() {
			if(this.audio.active) {
this._debug('audioPlay')
				let audioPromise = this.audio.obj.play()

				if(audioPromise !== undefined) {
				
					audioPromise.catch(e => {
						
						console.error('Audio error:', e)
						
						if(e.name === 'NotAllowedError') {
							
							this.notify.type = 'error'

							this.notify.message = 'Предоставьте разрешение на автовоспроизведение'

							this.notify.show = true

						}

						else if(e.name === 'NotSupportedError') {
							
							this.notify.type = 'error'

							this.notify.message = 'Формат аудио не поддерживается'

							this.notify.show = true

						}
					})
				
				}

			}
		},

	},
	
}

</script>

<style scoped>

/* https://stackoverflow.com/questions/60903847/how-to-change-vuetify-v-icon-color-in-css */
.highlight {
	color: red !important;
}

/* https://stackoverflow.com/questions/49949548/how-to-set-the-height-of-vuetify-card/49953110 */
/*
iframe {
	border: none;
	width: 100%;
	height: 92.5%;

			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

}
*/

iframe {
	border: none !important;
	position: absolute !important;
	top: 0 !important;
	right: 0 !important;
	bottom: 0 !important;
	left: 0 !important;
}

</style>